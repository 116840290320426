import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import SlickSlider, {Settings as SlickSettings, SwipeDirection} from 'react-slick';
import classNames from 'classnames';
import './SliderGallery.st.css';
import {keyboardEvents} from '../../../constants';
import {NavArrowLeft, NavArrowRight} from '../../../icons/dist';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {IPropsInjectedByViewerScript} from '../../../types/sliderGalleryTypes';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import {IGallerySantaProps, IProduct} from '../../../types/galleryTypes';
import autobind from 'autobind-decorator';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImage';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';
import s from './SliderGallery.scss';
import {withGlobals} from '../../../globalPropsContext';
import {ISliderGlobalProps} from '../../sliderGlobalStrategy';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';

const inlineCss = require('!raw-loader!../../../styles/inlineStyle.css');

export type SliderGalleryProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  ISliderGlobalProps['globals']
> &
  ISliderGlobalProps;

export interface SliderGalleryState {
  currentSliderIndex: number;
  height: number;
  inBrowser: boolean;
  isSwiping: boolean;
  lastMove: 'next' | 'prev';
  navigationArrowPosition: number;
  width: number;
}

type ExtendedSlickSlider = SlickSlider & {
  innerSlider: {
    track: {
      props: {
        currentSlide: number;
      };
    };
  };
};

export enum DataHook {
  LeftNavigationArrow = 'navigation-arrows-left-button',
  RightNavigationArrow = 'navigation-arrows-right-button',
}

interface IProductPlaceholder {
  id: string;
}

export const enum ArrowsDir {
  LEFT = 'left',
  RIGHT = 'right',
}

const NavigationArrow = ({dir}: {dir: ArrowsDir}) => {
  const p = {size: '22px', 'aria-hidden': true};
  switch (dir) {
    case ArrowsDir.LEFT:
      return <NavArrowLeft {...p} />;
    case ArrowsDir.RIGHT:
      return <NavArrowRight {...p} />;
  }
};

const NavigationControl = withGlobals(
  ({
    dir,
    onNav,
    arrowPosition,
    globals: {textsMap},
  }: {
    dir: ArrowsDir;
    onNav?(): void;
    arrowPosition?: number;
    globals: IPropsInjectedByViewerScript;
  }) => {
    const {sliderGalleryPreviousProduct, sliderGalleryNextProduct} = textsMap;
    /* istanbul ignore next: keyboard navigation is tested in e2e */
    const onKeypressNavigation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.keyCode === keyboardEvents.ENTER.keyCode) {
        onNav();
      }
    };

    return (
      <div className={classNames([s.navigationArrows, s[dir]])} style={{top: `${arrowPosition}px`}}>
        <button
          aria-label={dir === 'left' ? sliderGalleryPreviousProduct : sliderGalleryNextProduct}
          data-hook={dir === 'left' ? DataHook.LeftNavigationArrow : DataHook.RightNavigationArrow}
          className={s.resetButton}
          type="button"
          onClick={onNav}
          onKeyPress={onKeypressNavigation}>
          <NavigationArrow dir={dir} />
        </button>
      </div>
    );
  }
);

const WrapWithNavigation = ({
  withNavigationArrows,
  children,
  onNavigate,
  arrowPosition,
}: {
  withNavigationArrows: boolean;
  children: React.ReactElement;
  onNavigate?(dir: ArrowsDir): void;
  arrowPosition?: number;
}) => {
  return withNavigationArrows ? (
    <>
      <NavigationControl
        onNav={onNavigate && (() => onNavigate(ArrowsDir.LEFT))}
        dir={ArrowsDir.LEFT}
        arrowPosition={arrowPosition}
      />
      {children}
      <NavigationControl
        onNav={onNavigate && (() => onNavigate(ArrowsDir.RIGHT))}
        dir={ArrowsDir.RIGHT}
        arrowPosition={arrowPosition}
      />
    </>
  ) : (
    children
  );
};

@withGlobals
export class SliderGallery extends React.Component<SliderGalleryProps, SliderGalleryState> {
  private _ref: HTMLDivElement;
  private _slickRef: ExtendedSlickSlider;
  private imagesLoaded = false;
  private galleryKey;
  private a11yAnnouncer: Announcer;

  constructor(props: SliderGalleryProps) {
    super(props);
    this.state = this.getInitialState();
    this.galleryKey = !this.isEmptyState ? this.products[0].id : '';
  }

  private reportLoad() {
    const {globals} = this.props;
    if (globals.isInteractive && this.imagesLoaded) {
      globals.appLoadBI.loaded();
    }
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('slider-announcer');
    const {registerToComponentDidLayout} = this.props.host;
    registerToComponentDidLayout(this.reportAppLoaded);

    if (!this._ref) {
      return;
    }

    this.updateState();
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private updateState() {
    const {clientHeight, clientWidth} = this._ref.parentElement;
    this.setState(
      {
        height: clientHeight,
        width: clientWidth,
        inBrowser: true,
      },
      () => {
        this.updateNavigationArrowsPosition();

        imagesLoaded(this._ref.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
          this.imagesLoaded = true;
          this.reportLoad();
          this.props.globals.updateLayout && this.props.globals.updateLayout();
        });
      }
    );
  }

  public componentDidUpdate(prevProps: SliderGalleryProps) {
    if (!this._ref) {
      return;
    }
    /* istanbul ignore next: swipe is tested in sled */
    if (prevProps.isLoaded !== this.props.isLoaded) {
      this.updateState();
    }

    if (
      _.get(prevProps, ['globals', 'isInteractive']) !== _.get(this.props, ['globals', 'isInteractive']) &&
      this.props.globals.isInteractive === true
    ) {
      this.reportLoad();
    }
    this.updateSlidesKeyboardNavigation();

    if (!this.isEmptyState && this.products[0].id !== this.galleryKey) {
      this.resetNavigation();
      this.galleryKey = this.products[0].id;
    }
  }

  private resetNavigation() {
    this._slickRef.slickGoTo(0, true);
    this.setState({currentSliderIndex: 0});
  }

  private get shouldShowSlider(): boolean {
    return this.props.isLoaded;
  }

  private get isEmptyState(): boolean {
    const {
      globals: {products},
    } = this.props;
    return !products || products.length === 0;
  }

  private get products(): IProduct[] | IProductPlaceholder[] {
    const {products} = this.props.globals;
    if (!this.isEmptyState) {
      return products;
    }

    return _.range(0, this.slidesToShow).map((__, index) => ({
      id: index.toString(),
    }));
  }

  public renderEmptyState(): JSX.Element {
    const {styleParams} = this.props.globals;
    const productSpacingInPx = `${styleParams.numbers.galleryMargin}px`;
    return (
      <WrapWithNavigation withNavigationArrows={false}>
        <SlickSlider
          className={s.sliderGallerySlick}
          data-hook="slider-gallery-slick"
          {...this.getSlickSettings()}
          ref={slider => (this._slickRef = slider as ExtendedSlickSlider)}>
          {(this.products as IProductPlaceholder[]).map(product => (
            <div key={product.id} className={s.sliderGallerySlideContainer}>
              <div
                data-hook="slider-gallery-slide"
                style={{
                  height: '100%',
                  paddingLeft: productSpacingInPx,
                  paddingRight: productSpacingInPx,
                }}>
                <ProductPlaceholder />
              </div>
            </div>
          ))}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private get currentActiveSlideIndex(): number {
    return this._slickRef && this._slickRef.innerSlider.track.props.currentSlide;
  }

  @autobind
  private onNavigate(target: ArrowsDir | number) {
    if (target === 'right') {
      this._slickRef.slickNext();
      this.updateCurrentActiveSliderIndex();
      this.setState({
        lastMove: 'next',
      });
    } else {
      this._slickRef.slickPrev();
      this.setState({
        lastMove: 'prev',
      });
    }
  }

  public renderSlides(): JSX.Element {
    const {isSwiping, inBrowser, navigationArrowPosition} = this.state;
    const {styleParams} = this.props.globals;
    const productSpacingInPx = `${styleParams.numbers.galleryMargin}px`;
    return (
      <WrapWithNavigation
        withNavigationArrows={inBrowser && !this.shouldUseArrowlessMobileSlider}
        onNavigate={this.onNavigate}
        arrowPosition={navigationArrowPosition}>
        <SlickSlider
          className={s.sliderGallerySlick}
          data-hook="slider-gallery-slick"
          {...this.getSlickSettings()}
          ref={slider => (this._slickRef = slider as ExtendedSlickSlider)}>
          {(this.products as IProduct[]).map((product, i) => (
            <div key={i} className={s.sliderGallerySlideContainer}>
              <div
                data-hook="slider-gallery-slide"
                style={{
                  height: '100%',
                  paddingLeft: productSpacingInPx,
                  paddingRight: productSpacingInPx,
                }}>
                <ProductItemWithGlobals
                  disabled={isSwiping}
                  product={product}
                  style={{height: '100%'}}
                  index={i}
                  a11yAnnouncer={this.a11yAnnouncer}
                />
              </div>
            </div>
          ))}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private get slidesToShow(): number {
    const {
      globals: {
        styleParams: {
          numbers: {galleryColumns},
        },
        isMobile,
      },
    } = this.props;
    return isMobile ? 1 : galleryColumns;
  }

  @autobind
  private loadMoreProducts() {
    const {getCategoryProducts} = this.props.globals;
    const {lastMove} = this.state;
    const currentIndex = this.currentActiveSlideIndex;

    if (!currentIndex) {
      return;
    }

    const lastNextProductIndex = this.products.findIndex(product => (product as any).isFake);
    const lastPrevProductIndex = _.findLastIndex(this.products, product => (product as any).isFake);

    if (lastPrevProductIndex < 0 || lastNextProductIndex < 0) {
      return;
    }

    const prevProductsThreshold = lastPrevProductIndex + this.numOfProductsToLoad + 1;
    const nextProductsThreshold = lastNextProductIndex - this.numOfProductsToLoad;

    if (lastMove === 'prev' && currentIndex <= prevProductsThreshold) {
      const offset = Math.max(lastNextProductIndex, lastPrevProductIndex - this.numOfProductsToLoad + 1);
      const limit = Math.min(this.numOfProductsToLoad, Math.abs(lastPrevProductIndex - lastNextProductIndex + 1));
      getCategoryProducts({offset, limit});
    } else if (lastMove === 'next' && currentIndex >= nextProductsThreshold) {
      const offset = lastNextProductIndex;
      const limit = Math.min(this.numOfProductsToLoad, Math.abs(lastPrevProductIndex - lastNextProductIndex + 1));
      getCategoryProducts({offset, limit});
    }
  }

  @autobind
  public getSlickSettings(): SlickSettings {
    const slidesToShow = this.slidesToShow;
    return {
      arrows: false,
      dots: false,
      centerMode: this.shouldUseArrowlessMobileSlider,
      slidesToShow,
      slidesToScroll: 1,
      speed: 400,
      lazyLoad: 'ondemand',
      onLazyLoad: this.loadMoreProducts,
      infinite: this.products.length > slidesToShow,
      beforeChange: () => {
        this.setState({
          isSwiping: true,
        });
      },
      onSwipe:
        /* istanbul ignore next: swipe is tested in e2e */
        (dir: SwipeDirection) => {
          this.setState({
            lastMove: dir === 'left' ? 'next' : 'prev',
          });
        },
      afterChange: () => {
        this.setState({
          isSwiping: false,
        });
        this.updateCurrentActiveSliderIndex();
        this.updateSlidesKeyboardNavigation();
      },
      accessibility: false,
      swipeToSlide: true,
      focusOnSelect: false,
    };
  }

  private get numOfProductsToLoad(): number {
    return this.slidesToShow * 2;
  }

  private getInitialState(): SliderGalleryState {
    const {
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    return {
      currentSliderIndex: 0,
      height,
      inBrowser: false,
      isSwiping: false,
      lastMove: 'next',
      navigationArrowPosition: 0,
      width,
    };
  }

  @autobind
  private reportAppLoaded() {
    const {onAppLoaded, globals} = this.props;
    if (globals.isInteractive && typeof onAppLoaded === 'function') {
      onAppLoaded();
    }
  }

  private updateNavigationArrowsPosition() {
    const imageContainerEl = this._ref.querySelector(`[data-hook="${ProductImagesDataHook.Images}"]`);
    if (!imageContainerEl) {
      return;
    }
    const arrowPosition = imageContainerEl.clientHeight / 2;
    this.setState({
      navigationArrowPosition: arrowPosition,
    });
  }

  public render() {
    const {globals} = this.props;
    const backgroundColor = _.get(globals.styleParams.colors, ['gallery_background', 'value'], 'transparent');
    const appClassNames = classNames(s.root, {isRTL: globals.isRTL});

    if (!this.shouldShowSlider || this.props.hideGallery) {
      return null;
    }

    const rendered = this.isEmptyState ? this.renderEmptyState() : this.renderSlides();
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineCss}} />
        <div
          data-hook="slider-gallery"
          data-slider-index={this.state.currentSliderIndex}
          style={{backgroundColor}}
          ref={r => (this._ref = r)}
          className={appClassNames}>
          {globals.showTitle && (
            <h2 data-hook="slider-gallery-title" className={s.title}>
              {globals.textsMap.sliderGalleryTitle}
            </h2>
          )}
          <div
            data-hook="slides-container"
            className={classNames(s.slidesContainer, {
              [s.fullWidth]: globals.styleParams.booleans.full_width,
              [s.arrowLess]: this.shouldUseArrowlessMobileSlider,
            })}>
            {rendered}
          </div>
        </div>
      </>
    );
  }

  @autobind
  private updateSlidesKeyboardNavigation() {
    Array.from(this._ref.querySelectorAll('.slick-active a')).forEach((e: HTMLAnchorElement) => (e.tabIndex = 0));
    Array.from(this._ref.querySelectorAll('.slick-slide:not(.slick-active) a')).forEach(
      (e: HTMLAnchorElement) => (e.tabIndex = -1)
    );
  }

  private get shouldUseArrowlessMobileSlider(): boolean {
    const {
      globals: {
        isMobile,
        experiments: {isArrowlessMobileSliderEnabled},
      },
    } = this.props;
    return isMobile && isArrowlessMobileSliderEnabled;
  }

  private updateCurrentActiveSliderIndex() {
    setTimeout(() => {
      this.setState({
        currentSliderIndex: this.currentActiveSlideIndex,
      });
    });
  }
}
